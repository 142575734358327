var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-actions",
        {
          attrs: {
            title: _vm.$t("Dados do Inventário"),
            "no-actions": "",
            "show-loading": _vm.busy || _vm.fetching,
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "status",
                      type: "label",
                      "is-badge": "",
                      label: _vm.$t("Status"),
                      options: _vm.stockInventoryStatusOptions(),
                    },
                    model: {
                      value: _vm.stockInventoryForm.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.stockInventoryForm, "status", $$v)
                      },
                      expression: "stockInventoryForm.status",
                    },
                  }),
                ],
                1
              ),
              _vm.invoiceId
                ? _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "invoice-number",
                          type: "label",
                          label: _vm.$t("Nota fiscal de quebra"),
                          "router-name": "invoice-maintain",
                          "router-params": { id: _vm.invoiceId },
                          "router-title": _vm.$t("Visualizar nota de quebra"),
                        },
                        model: {
                          value: _vm.invoiceNumber,
                          callback: function ($$v) {
                            _vm.invoiceNumber = $$v
                          },
                          expression: "invoiceNumber",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "product_classifications",
                      type: "status-badge-multiple",
                      label: _vm.$t("Classificações de produto"),
                      options: _vm.productClassificationsForInventory(),
                    },
                    model: {
                      value: _vm.stockInventoryForm.productClassifications,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.stockInventoryForm,
                          "productClassifications",
                          $$v
                        )
                      },
                      expression: "stockInventoryForm.productClassifications",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "store",
                      value: _vm.storeName,
                      type: "label",
                      label: _vm.$t("Loja"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "comments",
                      type: "label",
                      label: _vm.$t("Descrição"),
                    },
                    model: {
                      value: _vm.stockInventoryForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.stockInventoryForm, "description", $$v)
                      },
                      expression: "stockInventoryForm.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    ref: "groupCombo",
                    attrs: {
                      id: "product-group-id",
                      value: _vm.groupName,
                      label: _vm.$t("Grupo"),
                      type: "label",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "product-subgroup-id",
                      value: _vm.subgroupName,
                      type: "label",
                      label: _vm.$t("Subgrupo"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "product-brand-id",
                      value: _vm.brandName,
                      label: _vm.$t("Marca"),
                      type: "label",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "product-department-id",
                      value: _vm.departmentName,
                      label: _vm.$t("Departamento"),
                      type: "label",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "percentCompleted",
                      label: _vm.$t("Perc. Completado"),
                      type: "label",
                      filter: "percentage",
                    },
                    model: {
                      value: _vm.stockInventoryForm.percentCompleted,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.stockInventoryForm,
                          "percentCompleted",
                          $$v
                        )
                      },
                      expression: "stockInventoryForm.percentCompleted",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _vm.isBlindFilling
                    ? _c("FormulateInput", {
                        attrs: {
                          id: "differenceValue",
                          label: _vm.$t("Diferença Apurada"),
                          type: "label",
                        },
                        model: {
                          value: _vm.stockInventoryForm.differenceValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.stockInventoryForm,
                              "differenceValue",
                              $$v
                            )
                          },
                          expression: "stockInventoryForm.differenceValue",
                        },
                      })
                    : _c("FormulateInput", {
                        attrs: {
                          id: "differenceValue",
                          label: _vm.$t("Diferença Apurada"),
                          type: "label",
                          filter: "currency",
                        },
                        model: {
                          value: _vm.stockInventoryForm.differenceValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.stockInventoryForm,
                              "differenceValue",
                              $$v
                            )
                          },
                          expression: "stockInventoryForm.differenceValue",
                        },
                      }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "created-at",
                      value:
                        _vm.$options.filters.date(
                          _vm.stockInventoryForm.createdDate
                        ) +
                        " / " +
                        _vm.stockInventoryForm.createdBy,
                      label: _vm.$t("Criado em / por"),
                      type: "label",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showUserSection
            ? _c(
                "b-row",
                [
                  _vm.stockInventoryForm.completedDate
                    ? _c(
                        "b-col",
                        { attrs: { md: "3" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "completed_date",
                              value:
                                _vm.$options.filters.datetime(
                                  _vm.stockInventoryForm.completedDate
                                ) +
                                " / " +
                                (_vm.stockInventoryForm.completedUser
                                  ? _vm.stockInventoryForm.completedUser.name
                                  : "-"),
                              label: _vm.$t("Finalizado em / por"),
                              type: "label",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.stockInventoryForm.authorizePositiveDiffDate
                    ? _c(
                        "b-col",
                        { attrs: { md: "3" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "auth_positive_diff_date",
                              value:
                                _vm.$options.filters.datetime(
                                  _vm.stockInventoryForm
                                    .authorizePositiveDiffDate
                                ) +
                                " / " +
                                (_vm.stockInventoryForm
                                  .authorizePositiveDiffUser
                                  ? _vm.stockInventoryForm
                                      .authorizePositiveDiffUser.name
                                  : "-"),
                              label: _vm.$t(
                                "Diferença Positiva Autorizado em / por"
                              ),
                              type: "label",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.stockInventoryForm.authorizeNegativeDiffDate
                    ? _c(
                        "b-col",
                        { attrs: { md: "3" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "auth_negative_diff_date",
                              value:
                                _vm.$options.filters.datetime(
                                  _vm.stockInventoryForm
                                    .authorizeNegativeDiffDate
                                ) +
                                " / " +
                                (_vm.stockInventoryForm
                                  .authorizeNegativeDiffUser
                                  ? _vm.stockInventoryForm
                                      .authorizeNegativeDiffUser.name
                                  : "-"),
                              label: _vm.$t(
                                "Diferença Negativa Autorizado em / por"
                              ),
                              type: "label",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.stockInventoryForm.cancelationDate
        ? _c(
            "b-card-actions",
            {
              attrs: {
                title: _vm.$t("Dados do Cancelamento"),
                "no-actions": "",
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "cancelation_date",
                          value:
                            _vm.$options.filters.datetime(
                              _vm.stockInventoryForm.cancelationDate
                            ) +
                            " / " +
                            (_vm.stockInventoryForm.cancelationUser
                              ? _vm.stockInventoryForm.cancelationUser.name
                              : "-"),
                          label: _vm.$t("Cancelado em / por"),
                          type: "label",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "cancelation_reason",
                          value: _vm.stockInventoryForm.cancelationReason,
                          label: _vm.$t("Motivo"),
                          type: "label",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.getSkus },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c(
            "e-filters",
            {
              staticClass: "mb-2 card-bordered",
              attrs: {
                "search-text": _vm.$t("Filtrar"),
                searching: _vm.fetching,
                title: _vm.$t("Filtrar Produtos"),
              },
              on: { search: _vm.getSkus, reset: _vm.resetFiltersLocal },
              scopedSlots: _vm._u([
                {
                  key: "after_buttons",
                  fn: function () {
                    return [
                      _c("e-button", {
                        staticClass: "ml-1 btn-sm-block",
                        attrs: {
                          id: "download",
                          variant: "primary",
                          icon: "download",
                          busy: _vm.fetching,
                          text: _vm.$t("Baixar Relatório"),
                        },
                        on: { click: _vm.downloadReport },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "ean",
                          type: "number",
                          label: _vm.$t("Código"),
                          placeholder: _vm.$t("Código"),
                        },
                        model: {
                          value: _vm.filters.skuId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "skuId", $$v)
                          },
                          expression: "filters.skuId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "ean",
                          type: "number",
                          label: _vm.$t("EAN/GTIN"),
                          placeholder: _vm.$t("Código de barras"),
                        },
                        model: {
                          value: _vm.filters.ean,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "ean", $$v)
                          },
                          expression: "filters.ean",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "5" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "name",
                          label: _vm.$t("Nome"),
                          placeholder: _vm.$t("Nome"),
                        },
                        model: {
                          value: _vm.filters.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "name", $$v)
                          },
                          expression: "filters.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "brand",
                          type: "vue-select",
                          label: _vm.$t("Marca"),
                          placeholder: _vm.$t("Selecione uma marca"),
                          options: _vm.getComboBrands,
                        },
                        model: {
                          value: _vm.filters.brand,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "brand", $$v)
                          },
                          expression: "filters.brand",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "group",
                          type: "vue-select",
                          label: _vm.$t("Grupo"),
                          placeholder: _vm.$t("Selecione um grupo"),
                          options: _vm.getComboGroups,
                        },
                        on: { input: _vm.selectGroup },
                        model: {
                          value: _vm.filters.group,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "group", $$v)
                          },
                          expression: "filters.group",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "subgroup",
                          type: "vue-select",
                          label: _vm.$t("Subgrupo"),
                          placeholder: _vm.$t("Selecione um subgrupo"),
                          options: _vm.getComboSubgroups,
                          disabled: !_vm.filters.group,
                        },
                        model: {
                          value: _vm.filters.subgroup,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "subgroup", $$v)
                          },
                          expression: "filters.subgroup",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "department",
                          type: "vue-select",
                          label: _vm.$t("Departamento"),
                          placeholder: _vm.$t("Selecione um departamento"),
                          options: _vm.getComboDepartments,
                        },
                        model: {
                          value: _vm.filters.department,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "department", $$v)
                          },
                          expression: "filters.department",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            ref: "table-skus",
            staticClass: "mb-1 bordered",
            attrs: {
              "show-empty": "",
              striped: "",
              responsive: "",
              fields: _vm.fields,
              items: _vm.stockInventorySkus,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getSkus,
            },
            scopedSlots: _vm._u([
              {
                key: "head(stockInSystem)",
                fn: function (ref) {
                  var label = ref.label
                  return [
                    _c(
                      "span",
                      [
                        _c("e-instructions", {
                          attrs: {
                            target: "instruction_stock_in_system",
                            "container-class": "mr-0",
                            instructions: [
                              {
                                text: _vm.$t(
                                  "STOCK_INVENTORY.VIEW.TABLE_INSTRUCTION.STOCK_IN_SYSTEM_1"
                                ),
                              },
                            ],
                          },
                        }),
                        _vm._v(" " + _vm._s(label.toUpperCase()) + " "),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "head(stockCounted)",
                fn: function (ref) {
                  var label = ref.label
                  return [
                    _c(
                      "span",
                      [
                        _c("e-instructions", {
                          attrs: {
                            target: "instruction_counted",
                            "container-class": "mr-0",
                            instruction: _vm.$t(
                              "STOCK_INVENTORY.VIEW.TABLE_INSTRUCTION.STOCK_COUNTED_1"
                            ),
                          },
                        }),
                        _vm._v(" " + _vm._s(label.toUpperCase()) + " "),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "head(timeCounted)",
                fn: function (ref) {
                  var label = ref.label
                  return [
                    _c(
                      "span",
                      [
                        _c("e-instructions", {
                          attrs: {
                            target: "instruction_counted_time",
                            "container-class": "mr-0",
                            instruction: _vm.$t(
                              "STOCK_INVENTORY.VIEW.TABLE_INSTRUCTION.STOCK_COUNTED_TIME_1"
                            ),
                          },
                        }),
                        _vm._v(" " + _vm._s(label.toUpperCase()) + " "),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "head(stockDifference)",
                fn: function (ref) {
                  var label = ref.label
                  return [
                    _c(
                      "span",
                      [
                        _c("e-instructions", {
                          attrs: {
                            target: "instruction_stock_diff",
                            "container-class": "mr-0",
                            instructions: [
                              {
                                text: _vm.$t(
                                  "STOCK_INVENTORY.VIEW.TABLE_INSTRUCTION.STOCK_DIFF_1"
                                ),
                              },
                              {
                                text: _vm.$t(
                                  "STOCK_INVENTORY.VIEW.TABLE_INSTRUCTION.STOCK_DIFF_2"
                                ),
                              },
                            ],
                          },
                        }),
                        _vm._v(" " + _vm._s(label.toUpperCase()) + " "),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "head(stockLendingOrRent)",
                fn: function (ref) {
                  var label = ref.label
                  return [
                    _c(
                      "span",
                      [
                        _c("e-instructions", {
                          attrs: {
                            target: "instruction_stock_lending_or_rent",
                            "container-class": "mr-0",
                            instructions: [
                              {
                                text: _vm.$t(
                                  "STOCK_INVENTORY.VIEW.TABLE_INSTRUCTION.STOCK_LENDING_OR_RENT_1"
                                ),
                              },
                            ],
                          },
                        }),
                        _vm._v(" " + _vm._s(label.toUpperCase()) + " "),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(name)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("e-product-router-link", {
                      attrs: {
                        "sku-id": item.skuId,
                        "product-id": item.productId,
                        "product-name": item.name,
                        ean: item.ean,
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(stockInSystem)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm.isBlindFilling
                      ? _c("p", [_vm._v(" - ")])
                      : [
                          _c("p", { class: "m-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("number")(item.stockInSystem, 3, true)
                                ) +
                                " "
                            ),
                          ]),
                        ],
                  ]
                },
              },
              {
                key: "cell(stockCounted)",
                fn: function (row) {
                  return [
                    !_vm.readOnly && !row.item.completed
                      ? _c("FormulateInput", {
                          staticClass: "text-right",
                          attrs: {
                            id: "sku-stock-counted-" + row.index,
                            type: "text-number",
                            precision: 3,
                          },
                          on: {
                            "blur-context": function ($event) {
                              return _vm.updateStockCounted(row.item)
                            },
                          },
                          model: {
                            value: row.item.stockCounted,
                            callback: function ($$v) {
                              _vm.$set(row.item, "stockCounted", $$v)
                            },
                            expression: "row.item.stockCounted",
                          },
                        })
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center",
                            },
                          },
                          [
                            !_vm.readOnly
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      margin: "3px 0",
                                    },
                                  },
                                  [
                                    _c("e-button", {
                                      staticClass: "p-5",
                                      attrs: {
                                        icon: "trash",
                                        variant: "danger",
                                        title: _vm.$t("Excluir contagem"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetStockCounted(row.item)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "justify-content-center",
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "margin-left": "5px",
                                  flex: "1",
                                },
                              },
                              [
                                row.item.stockCounted != null
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("number")(
                                              row.item.stockCounted,
                                              3,
                                              true
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [_vm._v(" - ")]),
                              ]
                            ),
                          ]
                        ),
                  ]
                },
              },
              {
                key: "cell(timeCounted)",
                fn: function (row) {
                  return [
                    !_vm.readOnly
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "sku-time-counted-" + row.index,
                            type: "time",
                            disabled: row.item.busy,
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.updateStockSkuTimeCounted(row.item)
                            },
                          },
                          model: {
                            value: row.item.timeCounted,
                            callback: function ($$v) {
                              _vm.$set(row.item, "timeCounted", $$v)
                            },
                            expression: "row.item.timeCounted",
                          },
                        })
                      : _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("time")(row.item.stockCountedDate, "-")
                              ) +
                              " "
                          ),
                        ]),
                  ]
                },
              },
              {
                key: "cell(stockDifference)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm.isBlindFilling
                      ? _c("p", [_vm._v(" - ")])
                      : [
                          _c(
                            "p",
                            {
                              class:
                                "m-0 " +
                                _vm.addValueOperationStyle(
                                  item.stockDifference
                                ),
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("number")(
                                      item.stockDifference,
                                      3,
                                      true
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          !_vm.fetching
                            ? _c(
                                "div",
                                { class: "m-0" },
                                [
                                  _c("e-router-link", {
                                    attrs: {
                                      id: "movement_preview-" + item.skuId,
                                      value: "( " + item.stockMovemented + " )",
                                      title: _vm.$t(
                                        "Quantidade movimentada após horário da contagem"
                                      ),
                                      to: {},
                                    },
                                  }),
                                  _c("product-movement-popup-table", {
                                    attrs: {
                                      id: item.skuId,
                                      target: "movement_preview-" + item.skuId,
                                      "store-id":
                                        _vm.stockInventoryForm.store.id,
                                      "sku-id": item.skuId,
                                      from: item.stockCountedDate,
                                      to: item.stockInSystemDate,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                  ]
                },
              },
              {
                key: "cell(stockLendingOrRent)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm.isBlindFilling
                      ? _c("p", [_vm._v(" - ")])
                      : _c("e-router-link", {
                          attrs: {
                            id: "reclaim_product-" + item.skuId,
                            header: _vm.$t(
                              "Comodato/Aluguel fora do estoque aguardando retorno"
                            ),
                            value:
                              "" +
                              _vm.$options.filters.number(
                                item.stockLendingOrRent || 0,
                                3,
                                true
                              ),
                            links: [
                              {
                                title: "Retorno de produtos",
                                to: _vm.getRouteToReclaimProductsList(item),
                              },
                            ],
                          },
                        }),
                  ]
                },
              },
              {
                key: "cell(comments)",
                fn: function (row) {
                  return [
                    !_vm.readOnly
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "sku-comments-" + row.index,
                            type: "textarea",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.updateStockComments(row.item)
                            },
                          },
                          model: {
                            value: row.item.comments,
                            callback: function ($$v) {
                              _vm.$set(row.item, "comments", $$v)
                            },
                            expression: "row.item.comments",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "user-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("inventory-cancelation-modal", {
        ref: "inventoryCancelationModal",
        attrs: { "invoice-id": _vm.invoiceId },
        on: { "after-confirm": _vm.onReturn },
      }),
      _c("fab", {
        attrs: {
          "main-tooltip": _vm.$t("Ações"),
          "main-icon": "keyboard_command_key",
          actions: _vm.actions,
          busy: _vm.busy,
        },
        on: {
          save: _vm.onFinishInventory,
          return: _vm.onReturn,
          "consult-invoice": _vm.onConsultInvoice,
          cancel: _vm.onCancel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }