var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-inventory-cancelation",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "hide-footer": "",
        size: "md",
        centered: "",
      },
      on: { hidden: _vm.onHideActions },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c("p", { staticClass: "h4" }, [
                _vm._v(" " + _vm._s(_vm.$t("Cancelar Inventário")) + " "),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "FormulateForm",
        { ref: "cancelationForm", attrs: { name: "cancelationForm" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex flex-column", attrs: { md: "12" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "w-100 required",
                        attrs: {
                          id: "cancelation_modal-reason",
                          name: "reason",
                          type: "textarea",
                          validation: "required",
                          rows: "4",
                          label: _vm.$t("Motivo do cancelamento do inventário"),
                        },
                        model: {
                          value: _vm.form.cancelationReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cancelationReason", $$v)
                          },
                          expression: "form.cancelationReason",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.invoiceId
                    ? _c(
                        "b-col",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { md: "12" },
                        },
                        [
                          _c("FormulateInput", {
                            staticClass: "w-100 required",
                            attrs: {
                              id: "cancelation_modal-invoice_justification",
                              name: "invoiceJustification",
                              type: "textarea",
                              validation: "required|min:15",
                              rows: "4",
                              label: _vm.$t(
                                "Justificativa do cancelamento da Nota fiscal"
                              ),
                            },
                            model: {
                              value: _vm.form.invoiceJustification,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "invoiceJustification", $$v)
                              },
                              expression: "form.invoiceJustification",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-end",
                      staticStyle: { gap: "10px" },
                    },
                    [
                      _c("e-button", {
                        attrs: {
                          text: _vm.$t("Voltar"),
                          "text-shortcuts": ["ESC"],
                          variant: "danger",
                        },
                        on: { click: _vm.onHideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          variant: "primary",
                          type: "submit",
                          busy: _vm.busy,
                          text: _vm.$t("Confirmar"),
                        },
                        on: { click: _vm.onConfirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }