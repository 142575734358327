<template>
  <div>
    <b-card-actions
      :title="$t('Dados do Inventário')"
      no-actions
      :show-loading="busy || fetching"
    >
      <b-row>
        <b-col md="2">
          <FormulateInput
            id="status"
            v-model="stockInventoryForm.status"
            type="label"
            is-badge
            :label="$t('Status')"
            :options="stockInventoryStatusOptions()"
          />
        </b-col>
        <b-col
          v-if="invoiceId"
          md="3"
        >
          <FormulateInput
            id="invoice-number"
            v-model="invoiceNumber"
            type="label"
            :label="$t('Nota fiscal de quebra')"
            router-name="invoice-maintain"
            :router-params="{ id: invoiceId }"
            :router-title="$t('Visualizar nota de quebra')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="product_classifications"
            v-model="stockInventoryForm.productClassifications"
            type="status-badge-multiple"
            :label="$t('Classificações de produto')"
            :options="productClassificationsForInventory()"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <FormulateInput
            id="store"
            :value="storeName"
            type="label"
            :label="$t('Loja')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="comments"
            v-model="stockInventoryForm.description"
            type="label"
            :label="$t('Descrição')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="product-group-id"
            ref="groupCombo"
            :value="groupName"
            :label="$t('Grupo')"
            type="label"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="product-subgroup-id"
            :value="subgroupName"
            type="label"
            :label="$t('Subgrupo')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="product-brand-id"
            :value="brandName"
            :label="$t('Marca')"
            type="label"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="product-department-id"
            :value="departmentName"
            :label="$t('Departamento')"
            type="label"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="percentCompleted"
            v-model="stockInventoryForm.percentCompleted"
            :label="$t('Perc. Completado')"
            type="label"
            filter="percentage"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            v-if="isBlindFilling"
            id="differenceValue"
            v-model="stockInventoryForm.differenceValue"
            :label="$t('Diferença Apurada')"
            type="label"
          />
          <FormulateInput
            v-else
            id="differenceValue"
            v-model="stockInventoryForm.differenceValue"
            :label="$t('Diferença Apurada')"
            type="label"
            filter="currency"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="created-at"
            :value="`${$options.filters.date(stockInventoryForm.createdDate)} / ${
              stockInventoryForm.createdBy
            }`"
            :label="$t('Criado em / por')"
            type="label"
          />
        </b-col>
      </b-row>

      <b-row v-if="showUserSection">
        <b-col
          v-if="stockInventoryForm.completedDate"
          md="3"
        >
          <FormulateInput
            id="completed_date"
            :value="`${$options.filters.datetime(stockInventoryForm.completedDate)} / ${
              stockInventoryForm.completedUser ? stockInventoryForm.completedUser.name : '-'
            }`"
            :label="$t('Finalizado em / por')"
            type="label"
          />
        </b-col>

        <b-col
          v-if="stockInventoryForm.authorizePositiveDiffDate"
          md="3"
        >
          <FormulateInput
            id="auth_positive_diff_date"
            :value="`${$options.filters.datetime(stockInventoryForm.authorizePositiveDiffDate)} / ${
              stockInventoryForm.authorizePositiveDiffUser
                ? stockInventoryForm.authorizePositiveDiffUser.name
                : '-'
            }`"
            :label="$t('Diferença Positiva Autorizado em / por')"
            type="label"
          />
        </b-col>

        <b-col
          v-if="stockInventoryForm.authorizeNegativeDiffDate"
          md="3"
        >
          <FormulateInput
            id="auth_negative_diff_date"
            :value="`${$options.filters.datetime(stockInventoryForm.authorizeNegativeDiffDate)} / ${
              stockInventoryForm.authorizeNegativeDiffUser
                ? stockInventoryForm.authorizeNegativeDiffUser.name
                : '-'
            }`"
            :label="$t('Diferença Negativa Autorizado em / por')"
            type="label"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card-actions
      v-if="stockInventoryForm.cancelationDate"
      :title="$t('Dados do Cancelamento')"
      no-actions
    >
      <b-row>
        <b-col md="3">
          <FormulateInput
            id="cancelation_date"
            :value="`${$options.filters.datetime(stockInventoryForm.cancelationDate)} / ${
              stockInventoryForm.cancelationUser ? stockInventoryForm.cancelationUser.name : '-'
            }`"
            :label="$t('Cancelado em / por')"
            type="label"
          />
        </b-col>

        <b-col>
          <FormulateInput
            id="cancelation_reason"
            :value="stockInventoryForm.cancelationReason"
            :label="$t('Motivo')"
            type="label"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="getSkus"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <e-filters
        :search-text="$t('Filtrar')"
        :searching="fetching"
        :title="$t('Filtrar Produtos')"
        class="mb-2 card-bordered"
        @search="getSkus"
        @reset="resetFiltersLocal"
      >
        <template #after_buttons>
          <e-button
            id="download"
            class="ml-1 btn-sm-block"
            variant="primary"
            icon="download"
            :busy="fetching"
            :text="$t('Baixar Relatório')"
            @click="downloadReport"
          />
        </template>
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="ean"
              v-model="filters.skuId"
              type="number"
              :label="$t('Código')"
              :placeholder="$t('Código')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="ean"
              v-model="filters.ean"
              type="number"
              :label="$t('EAN/GTIN')"
              :placeholder="$t('Código de barras')"
            />
          </b-col>
          <b-col md="5">
            <FormulateInput
              id="name"
              v-model="filters.name"
              :label="$t('Nome')"
              :placeholder="$t('Nome')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="brand"
              v-model="filters.brand"
              type="vue-select"
              :label="$t('Marca')"
              :placeholder="$t('Selecione uma marca')"
              :options="getComboBrands"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="group"
              v-model="filters.group"
              type="vue-select"
              :label="$t('Grupo')"
              :placeholder="$t('Selecione um grupo')"
              :options="getComboGroups"
              @input="selectGroup"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="subgroup"
              v-model="filters.subgroup"
              type="vue-select"
              :label="$t('Subgrupo')"
              :placeholder="$t('Selecione um subgrupo')"
              :options="getComboSubgroups"
              :disabled="!filters.group"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="department"
              v-model="filters.department"
              type="vue-select"
              :label="$t('Departamento')"
              :placeholder="$t('Selecione um departamento')"
              :options="getComboDepartments"
            />
          </b-col>
        </b-row>
      </e-filters>

      <b-table
        ref="table-skus"
        show-empty
        striped
        responsive
        class="mb-1 bordered"
        :fields="fields"
        :items="stockInventorySkus"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getSkus"
      >
        <!-- head -->
        <template #head(stockInSystem)="{ label }">
          <span>
            <e-instructions
              target="instruction_stock_in_system"
              container-class="mr-0"
              :instructions="[
                { text: $t('STOCK_INVENTORY.VIEW.TABLE_INSTRUCTION.STOCK_IN_SYSTEM_1') },
              ]"
            />
            {{ label.toUpperCase() }}
          </span>
        </template>

        <template #head(stockCounted)="{ label }">
          <span>
            <e-instructions
              target="instruction_counted"
              container-class="mr-0"
              :instruction="$t('STOCK_INVENTORY.VIEW.TABLE_INSTRUCTION.STOCK_COUNTED_1')"
            />
            {{ label.toUpperCase() }}
          </span>
        </template>

        <template #head(timeCounted)="{ label }">
          <span>
            <e-instructions
              target="instruction_counted_time"
              container-class="mr-0"
              :instruction="$t('STOCK_INVENTORY.VIEW.TABLE_INSTRUCTION.STOCK_COUNTED_TIME_1')"
            />
            {{ label.toUpperCase() }}
          </span>
        </template>

        <template #head(stockDifference)="{ label }">
          <span>
            <e-instructions
              target="instruction_stock_diff"
              container-class="mr-0"
              :instructions="[
                { text: $t('STOCK_INVENTORY.VIEW.TABLE_INSTRUCTION.STOCK_DIFF_1') },
                { text: $t('STOCK_INVENTORY.VIEW.TABLE_INSTRUCTION.STOCK_DIFF_2') },
              ]"
            />
            {{ label.toUpperCase() }}
          </span>
        </template>

        <template #head(stockLendingOrRent)="{ label }">
          <span>
            <e-instructions
              target="instruction_stock_lending_or_rent"
              container-class="mr-0"
              :instructions="[
                { text: $t('STOCK_INVENTORY.VIEW.TABLE_INSTRUCTION.STOCK_LENDING_OR_RENT_1') },
              ]"
            />
            {{ label.toUpperCase() }}
          </span>
        </template>

        <!-- body -->
        <template #cell(name)="{ item }">
          <e-product-router-link
            :sku-id="item.skuId"
            :product-id="item.productId"
            :product-name="item.name"
            :ean="item.ean"
          />
        </template>

        <template #cell(stockInSystem)="{ item }">
          <p v-if="isBlindFilling">
            -
          </p>
          <template v-else>
            <p :class="`m-0`">
              {{ item.stockInSystem | number(3, true) }}
            </p>
          </template>
        </template>

        <template #cell(stockCounted)="row">
          <FormulateInput
            v-if="!readOnly && !row.item.completed"
            :id="`sku-stock-counted-${row.index}`"
            v-model="row.item.stockCounted"
            type="text-number"
            :precision="3"
            class="text-right"
            @blur-context="updateStockCounted(row.item)"
          />
          <div
            v-else
            style="display: flex; justify-content: center"
          >
            <div
              v-if="!readOnly"
              style="display: flex; align-items; center; margin: 3px 0;"
            >
              <e-button
                icon="trash"
                variant="danger"
                class="p-5"
                :title="$t('Excluir contagem')"
                @click="resetStockCounted(row.item)"
              />
            </div>
            <div
              style="display: flex; align-items: center; margin-left: 5px; flex: 1"
              class="justify-content-center"
            >
              <span v-if="row.item.stockCounted != null">
                {{ row.item.stockCounted | number(3, true) }}
              </span>
              <span v-else> - </span>
            </div>
          </div>
        </template>

        <template #cell(timeCounted)="row">
          <FormulateInput
            v-if="!readOnly"
            :id="`sku-time-counted-${row.index}`"
            v-model="row.item.timeCounted"
            type="time"
            :disabled="row.item.busy"
            @blur="updateStockSkuTimeCounted(row.item)"
          />
          <div v-else>
            {{ row.item.stockCountedDate | time('-') }}
          </div>
        </template>

        <template #cell(stockDifference)="{ item }">
          <p v-if="isBlindFilling">
            -
          </p>
          <template v-else>
            <p :class="`m-0 ${addValueOperationStyle(item.stockDifference)}`">
              {{ item.stockDifference | number(3, true) }}
            </p>
            <!-- condicional necessário para destruir o popup e atualizar os dados dele -->
            <div
              v-if="!fetching"
              :class="`m-0`"
            >
              <e-router-link
                :id="`movement_preview-${item.skuId}`"
                :value="`( ${item.stockMovemented} )`"
                :title="$t('Quantidade movimentada após horário da contagem')"
                :to="{}"
              />
              <!-- não usar v-if diretamente no popup, se precisar coloque no elemento pai (para evitar duplicação de popup) -->
              <product-movement-popup-table
                :id="item.skuId"
                :target="`movement_preview-${item.skuId}`"
                :store-id="stockInventoryForm.store.id"
                :sku-id="item.skuId"
                :from="item.stockCountedDate"
                :to="item.stockInSystemDate"
              />
            </div>
          </template>
        </template>

        <template #cell(stockLendingOrRent)="{ item }">
          <p v-if="isBlindFilling">
            -
          </p>
          <e-router-link
            v-else
            :id="`reclaim_product-${item.skuId}`"
            :header="$t('Comodato/Aluguel fora do estoque aguardando retorno')"
            :value="`${$options.filters.number(item.stockLendingOrRent || 0, 3, true)}`"
            :links="[{ title: 'Retorno de produtos', to: getRouteToReclaimProductsList(item) }]"
          />
        </template>

        <template #cell(comments)="row">
          <FormulateInput
            v-if="!readOnly"
            :id="`sku-comments-${row.index}`"
            v-model="row.item.comments"
            type="textarea"
            @blur="updateStockComments(row.item)"
          />
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="user-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <inventory-cancelation-modal
      ref="inventoryCancelationModal"
      :invoice-id="invoiceId"
      @after-confirm="onReturn"
    />

    <fab
      :main-tooltip="$t('Ações')"
      main-icon="keyboard_command_key"
      :actions="actions"
      :busy="busy"
      @save="onFinishInventory"
      @return="onReturn"
      @consult-invoice="onConsultInvoice"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import { BCol, BRow, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { accountTypes, downloader, paymentTypes, productDomains, stockDomain } from '@/mixins'
import {
  EPaginationSummary,
  EPageSizeSelector,
  EButton,
  EProductRouterLink,
  EFilters,
  EInstructions,
} from '@/views/components'
import { currencySymbol } from '@/utils/number-utils'
import { exceptionTypeEnum, getApiErrorData } from '@/utils/error-utils'
import fab from '@/views/components/FAB.vue'
import moment from 'moment'
import ERouterLink from '@/views/components/ERouterLink.vue'
import delegablePermissions from '@/utils/delegable-permissions'
import InventoryCancelationModal from './components/InventoryCancelationModal.vue'
import ProductMovementPopupTable from '../stock-movement/components/ProductMovementPopupTable.vue'

export default {
  name: 'StockMovementMaintain',
  components: {
    BCardActions,
    BCol,
    BRow,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    EButton,
    EFilters,
    fab,
    EProductRouterLink,
    InventoryCancelationModal,
    ERouterLink,
    ProductMovementPopupTable,
    EInstructions,
  },

  mixins: [paymentTypes, accountTypes, stockDomain, downloader, productDomains],

  props: {
    readOnlyPage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      busy: false,
      fetching: false,
      readOnly: false,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapState('pages/stock/stockInventory/stockInventoryMaintain', [
      'stockInventoryForm',
      'stockInventorySkus',
      'invoiceId',
      'invoiceNumber',
      'sorting',
      'paging',
      'filters',
    ]),
    ...mapGetters('pages/catalog/products', [
      'getComboDepartments',
      'getComboBrands',
      'getComboGroups',
      'getComboSubgroups',
    ]),

    isBlindFilling() {
      return this.stockInventoryForm.status === this.stockInventoryStatusEnum.IN_PROGRESS
    },

    showUserSection() {
      return [
        this.stockInventoryStatusEnum.COMPLETED,
        this.stockInventoryStatusEnum.CANCELED,
      ].includes(this.stockInventoryForm.status)
    },

    storeName() {
      return this.$options.filters.storeName(this.stockInventoryForm.store) || '-'
    },
    groupName() {
      return this.stockInventoryForm.group?.name || '-'
    },
    subgroupName() {
      return this.stockInventoryForm.subgroup?.name || '-'
    },
    brandName() {
      return this.stockInventoryForm.brand?.name || '-'
    },
    departmentName() {
      return this.stockInventoryForm.department?.name || '-'
    },
    fields() {
      return [
        {
          label: this.$t('Produto'),
          key: 'name',
          sortKey: 'sku.name',
          sortable: true,
          thStyle: { minWidth: '300px' },
        },
        {
          label: this.$t('UN.'),
          key: 'unitOfMeasurement',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Custo Unit.'),
          key: 'unitCost',
          tdClass: 'text-right',
          thStyle: { width: '150px' },
          sortable: true,
          formatter: value => this.$options.filters.currency(value),
        },
        {
          label: this.$t('Estoque no Sistema'),
          key: 'stockInSystem',
          class: 'text-center',
          thStyle: { minWidth: '120px', maxWidth: '120px' },
          sortable: true,
          formatter: value =>
            this.isBlindFilling ? '-' : this.$options.filters.number(value, 3, true),
        },
        {
          label: this.$t('Estoque Contado'),
          key: 'stockCounted',
          class: 'text-center',
          sortable: true,
          thStyle: { width: '120px' },
        },
        {
          label: this.$t('Hora Contado'),
          key: 'timeCounted',
          sortKey: 'stockCountedDate',
          class: 'text-center',
          sortable: true,
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Comodato em aberto'), // qtd de comodatos e aluguéis
          key: 'stockLendingOrRent',
          class: 'text-center',
          thStyle: { minWidth: '130px', maxWidth: '130px' },
          sortable: true,
        },
        {
          label: this.$t('Diferença (Movimento)'),
          key: 'stockDifference',
          thStyle: { minWidth: '130px', maxWidth: '130px' },
          tdClass: 'text-center',
          sortable: true,
        },
        {
          label: `${this.$t('Diferença')} ${currencySymbol()}`,
          key: 'stockDifferenceCurrency',
          thClass: 'text-center',
          tdClass: (value, key, item) =>
            this.addValueOperationStyle(item.stockDifferenceCurrency, 'text-right'),
          thStyle: { width: '120px' },
          sortable: true,
          formatter: value => (this.isBlindFilling ? '-' : this.$options.filters.currency(value)),
        },
        {
          label: this.$t('Comentários'),
          key: 'comments',
          thStyle: { width: '200px', minWidth: '200px' },
        },
      ]
    },
    actions() {
      return [
        {
          name: 'return',
          icon: 'arrow_back',
          color: 'red',
          tooltip: this.$t('Voltar'),
        },
        {
          name: 'save',
          icon: 'save',
          color: 'green',
          tooltip: this.$t('Finalizar Inventário'),
          hide: this.readOnly || this.stockInventoryForm.percentCompleted < 1.0,
        },
        {
          name: 'consult-invoice',
          icon: 'search',
          color: 'green',
          tooltip: this.$t('Consultar Nota Fiscal Gerada'),
          hide: !this.invoiceId,
        },
        {
          name: 'cancel',
          icon: 'close',
          color: 'red',
          tooltip: this.$t('Cancelar inventário'),
          hide:
            !this.$can('Cancel', 'StockInventory') ||
            this.stockInventoryForm.status === this.stockInventoryStatusEnum.CANCELED,
        },
      ].filter(a => !a.hide)
    },
  },

  async mounted() {
    this.fetching = true
    this.readOnly = this.readOnlyPage

    try {
      await Promise.all([
        this.fetchProductCombos(),
        this.fetchInventory(this.$route.params.id),
        this.fetchInventorySkus(this.$route.params.id),
      ])

      const statusToReadOnly = [
        this.stockInventoryStatusEnum.COMPLETED,
        this.stockInventoryStatusEnum.INVOICE_PENDING,
        this.stockInventoryStatusEnum.CANCELED,
      ]
      if (statusToReadOnly.some(status => this.stockInventoryForm.status === status)) {
        this.readOnly = true
      }
    } catch (error) {
      this.showError({ error, message: this.$t('Não foi possível obter os dados do Inventário') })
      this.$router.push({ name: 'stock-inventory-list' })
    } finally {
      this.fetching = false
    }
  },

  methods: {
    ...mapActions('pages/stock/stockInventory/stockInventoryMaintain', [
      'fetchInventory',
      'fetchInventorySkus',
      'generateReport',
      'saveSkuStockCounted',
      'saveSkuComments',
      'saveSkuStockTimeCounted',
      'resetSkuStockCounted',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
      'finishInventory',
      'cancelInventory',
      'cleanState',
      'stockInventoryToInvoice',
    ]),
    ...mapActions('pages/catalog/products', ['setComboSubgroups', 'fetchProductCombos']),

    async getSkus() {
      this.$nextTick(async () => {
        this.fetching = true
        try {
          await this.fetchInventorySkus(this.$route.params.id)
        } catch (error) {
          this.showError({
            error,
            message: this.$t('Não foi possível obter os produtos do Inventário'),
          })
        } finally {
          this.fetching = false
        }
      })
    },
    async reloadSkusForResultsOpened() {
      if (this.stockInventoryForm.status === this.stockInventoryStatusEnum.COMPLETED_PENDING) {
        await this.getSkus()
      }
    },

    setSkuItemCounts(item, itemResponse) {
      /* eslint-disable no-param-reassign */
      item.stockDifference = itemResponse?.stockDifference
      item.stockDifferenceCurrency = itemResponse?.stockDifferenceCurrency
      item.stockLendingOrRent = itemResponse?.stockLendingOrRent

      item.stockInSystem =
        itemResponse.stockInSystem == null
          ? itemResponse.stockInSystem
          : itemResponse.stockInSystem + (itemResponse.stockLendingOrRent || 0)
      item.stockInSystemAtCounting = itemResponse?.stockInSystemAtCounting
      item.stockMovemented = item.stockInSystem - item.stockInSystemAtCounting

      item.stockCountedDate = itemResponse.stockCountedDate
      item.timeCounted = item.stockCountedDate
        ? moment(itemResponse.stockCountedDate).format('HH:mm')
        : null
      /* eslint-enable no-param-reassign */
    },

    async updateStockCounted(sku) {
      if (sku.stockCounted !== null) {
        this.busy = true
        // eslint-disable-next-line no-param-reassign
        sku.busy = true
        try {
          const result = await this.saveSkuStockCounted({
            skuId: sku.skuId,
            stockCounted: sku.stockCounted,
          })

          // eslint-disable-next-line no-param-reassign
          sku.completed = true
          this.setSkuItemCounts(sku, result)
        } catch (error) {
          this.showError({ error, message: this.$t('Não foi possível atualizar o dado') })
          this.getSkus()
        } finally {
          this.busy = false
          // eslint-disable-next-line no-param-reassign
          sku.busy = false
        }

        await this.reloadSkusForResultsOpened()
      }
    },

    async resetStockCounted(sku) {
      if (await this.confirm({ text: this.$t('Tem certeza que deseja excluir esta contagem?') })) {
        this.busy = true
        this.fetching = true
        try {
          const result = await this.resetSkuStockCounted(sku.skuId)
          // eslint-disable-next-line no-param-reassign
          sku.completed = false
          // eslint-disable-next-line no-param-reassign
          sku.stockCounted = null
          this.setSkuItemCounts(sku, result)
        } catch (error) {
          this.showError({ error, message: this.$t('Não foi possível atualizar o dado') })
        } finally {
          this.busy = false
          this.fetching = false
        }

        await this.reloadSkusForResultsOpened()
      }
    },

    async updateStockSkuTimeCounted(sku) {
      if (sku.timeCounted !== null) {
        this.busy = true
        try {
          const result = await this.saveSkuStockTimeCounted({
            skuId: sku.skuId,
            timeCounted: sku.timeCounted,
          })
          this.setSkuItemCounts(sku, result)
        } catch (error) {
          this.showError({ error, message: this.$t('Não foi possível atualizar o dado') })
          // eslint-disable-next-line no-param-reassign
          sku.timeCounted = sku.stockCountedDate
            ? moment(sku.stockCountedDate).format('HH:mm')
            : null
        } finally {
          this.busy = false
        }

        await this.reloadSkusForResultsOpened()
      }
    },

    async updateStockComments(sku) {
      if (sku.comments !== null) {
        this.busy = true
        try {
          await this.saveSkuComments({ skuId: sku.skuId, comments: sku.comments })
        } catch (error) {
          this.showError({ error, message: this.$t('Não foi possível atualizar o dado') })
          this.getSkus()
        } finally {
          this.busy = false
        }
      }
    },

    async downloadReport() {
      try {
        this.fetching = true
        const reportData = await this.generateReport(this.$route.params.id)

        if (reportData) {
          this.forceFileDownloadXlsx(
            reportData,
            `ContagemInventario_${this.$route.params.id}_-Loja_${this.stockInventoryForm?.store?.id}`
          )
        } else {
          this.showError({ message: this.$t('Não foi possível recuperar os dados do relatório') })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    selectGroup(idValue) {
      this.filters.subgroup = null
      this.setComboSubgroups(idValue)
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getSkus()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getSkus()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getSkus()
    },

    async onConsultInvoice() {
      this.$router.push({ name: 'invoice-maintain', params: { id: this.invoiceId } })
    },

    async onFinishInventory(delegation) {
      try {
        this.busy = true

        const confirmed = await this.confirm({
          title: this.$t('Confirma a finalização do inventário?'),
          text: this.$t(''),
        })
        if (!confirmed) return

        const result = await this.finishInventory({ delegation })

        if (result.status === this.stockInventoryStatusEnum.COMPLETED_PENDING) {
          await this.showInformation({
            title: this.$t('Diferenças entre "estoque contado" e "estoque no sistema"'),
            text: this.$t(
              'Favor revisar os itens com diferença e finalizar novamente o inventário.'
            ),
          })

          await Promise.all([this.fetchInventory(result.id), this.fetchInventorySkus(result.id)])
        } else if (result.invoiceId != null) {
          await this.showInformation({
            title: this.$t('Aguardando emissão da Nota Fiscal para finalizar o inventário'),
            text: this.$t('Gerado uma nota fiscal com os itens que possuem diferença negativa.'),
          })
          this.$router.push({ name: 'invoice-maintain', params: { id: result.invoiceId } })
        } else {
          this.showSuccess({ message: 'Inventário Finalizado com Sucesso' })
          this.cleanState()

          this.$router.push({ name: 'stock-inventory-list' })
        }
      } catch (error) {
        const errorData = getApiErrorData(error)
        const delegableValue = delegablePermissions.ERP_STOCK_INVENTORY_DIFF_LIMIT_ON_FINISH
        if (
          errorData.exceptionType === exceptionTypeEnum.PERMISSION_DENIED &&
          delegableValue.relatedExceptions?.includes(errorData.message)
        ) {
          this.modalAuthRef.authSync(delegableValue, this.stockInventoryForm.store.id, {
            confirmCallback: this.onFinishInventory,
            withHasPermission: false,
            errorData,
          })
        } else {
          this.showError({ error, message: this.$t('Não foi possível concluir a operação') })
        }
      } finally {
        this.busy = false
      }
    },

    async onCancel() {
      await this.$refs.inventoryCancelationModal.show(this.stockInventoryForm.id)
    },

    onReturn() {
      this.cleanState()
      this.$router.push({ name: 'stock-inventory-list' })
    },

    addValueOperationStyle(value, defaultStyle) {
      let clazz = defaultStyle

      if (value < 0) clazz += ' text-danger'
      else if (value > 0) clazz += ' text-primary'

      return clazz
    },

    getRouteToReclaimProductsList(item) {
      return {
        name: 'reclaim-list',
        query: {
          storeId: this.stockInventoryForm?.store?.id,
          skuId: item?.skuId,
          startDate: null,
          endDate: null,
        },
      }
    },
  },
}
</script>
<style></style>
