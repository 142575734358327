<template>
  <b-tooltip
    :id="`stock_movement_tooltip_${id}`"
    :ref="`stock_movement_tooltip_${id}`"
    :target="target"
    boundary="window"
    class="transparent-tooltip"
    triggers="click"
    placement="left"
    custom-class="custom-tooltip-inner"
  >
    <template #default>
      <div class="stock-movement-popup-table">
        <div class="p-1 d-flex justify-content-between align-items-center">
          <!-- Necessário destruir o componente pro texto atualizar -->
          <p
            class="m-0 font-weight-bold"
            :title="getPeriod"
          >
            {{ $t('Movimentações realizadas depois da contagem') }}
          </p>
          <feather-icon
            icon="XIcon"
            size="16"
            class="cursor-pointer"
            @click="onCloseTooltip"
          />
        </div>

        <div class="table-container">
          <b-table
            :id="`table_${id}`"
            responsive
            show-empty
            :busy="fetching"
            class="bordered table-tooltip"
            style="min-width: 400px; margin-top: 10px"
            :empty-text="getEmptyTableMessage($tc('STOCK_MOVEMENT_REASON.NAME'), 'female')"
            primary-key="id"
            :fields="fields"
            :items="getItems"
          >
            <template #table-busy>
              <div class="text-center">
                <strong>{{ $t('Buscando...') }}</strong>
              </div>
            </template>

            <template #cell(movementOrigin)="{ item }">
              <span> {{ item.movementOriginInfo.label }}</span>

              <e-router-link
                v-if="item.movementOriginInfo.routerName"
                :to="{
                  name: item.movementOriginInfo.routerName,
                  params: item.movementOriginInfo.routerParams,
                }"
                :value="item.movementOriginInfo.value"
              />
            </template>
          </b-table>
        </div>
      </div>
    </template>
  </b-tooltip>
</template>

<script>
import { BTable, BTooltip } from 'bootstrap-vue'
import { stockDomain } from '@/mixins'
import moment from 'moment'
import ERouterLink from '@/views/components/ERouterLink.vue'

export default {
  components: { BTable, BTooltip, ERouterLink },
  mixins: [stockDomain],
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
    storeId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    skuId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    from: {
      type: String,
      required: false,
      default: null,
    },
    to: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      fetching: false,
      tableKey: 0,
      items: [],
    }
  },

  computed: {
    // ...mapState('pages/sale/deliveryDashboard', ['orderItems']), // exemplo

    getPeriod() {
      return `Período de ${this.$options.filters.datetime(
        this.from,
        '-'
      )} até ${this.$options.filters.datetime(this.to, '-')}`
    },

    fields() {
      return [
        {
          label: this.$t('Data/Hora'),
          key: 'movementDate',
          class: 'text-center',
          thStyle: { width: '140px' },
          // sortable: true,
          formatter: value => this.$options.filters.datetime(value),
        },
        {
          label: this.$t('Tipo'),
          key: 'movementType',
          class: 'text-center',
          formatter: value => this.stockMovementTypeLabel[value] || '-',
        },
        {
          label: this.$t('Qtd.'),
          key: 'stockMovement',
          class: 'text-center',
          thStyle: { width: '100px' },
          // sortable: true,
          formatter: value => this.$options.filters.number(value, 3, true),
        },
        // {
        //   label: this.$t('Novo Estoque'),
        //   key: 'newStock',
        //   class: 'text-center',
        //   thStyle: { width: '100px' },
        //   sortable: true,
        //   formatter: value => this.$options.filters.number(value, 3, true),
        // },
        {
          label: this.$t('Origem'),
          key: 'movementOrigin',
          class: 'text-center',
          thStyle: { width: '180px' },
          // formatter: value => this.stockMovementOriginLabel[value] || '-',
        },
      ]
    },
    // TODO Se ficar muito pesado, usar esse fluxo (busca, armazena no vuex com chave/valor o resultado, e pega aqui usando a chave)
    // items() {
    //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   this.tableKey += 1
    //   return this.orderItems[this.orderId] || []
    // },
  },

  async mounted() {
    // await this.getItems()
  },

  methods: {
    onCloseTooltip() {
      this.$root.$emit('bv::hide::tooltip', `stock_movement_tooltip_${this.id}`)
    },

    async getItems() {
      if (!this.items.length) {
        try {
          this.fetching = true
          const now = moment().format()
          const dateParams = {
            from: now,
            to: now,
          }

          if (this.from && this.to) {
            dateParams.from = this.from
            dateParams.to = this.to
          }

          const { data } = await this.$http.get('/api/stock-movements', {
            params: {
              storeId: this.storeId,
              skuId: this.skuId,
              pageSize: 0,
              pageIndex: 0,
              sortBy: 'MovementDate Desc',
              ...dateParams,
            },
          })

          this.items = (data?.results || []).map(i => ({
            ...i,
            movementOriginInfo: this.getOriginInfo(i),
          }))

          return this.items
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      }

      return this.items || []
    },

    getOriginInfo(item) {
      const config = () => {
        switch (item.movementOrigin) {
          case 'Invoice':
            return {
              value: item.invoiceNumber,
              routerName: 'invoice-maintain',
              routerParams: { id: item.invoiceId },
            }
          case 'PurchaseOrder':
            return {
              value: item.purchaseOrder.id,
              routerName: 'purchase-order-details',
              routerParams: { id: item.purchaseOrderId },
            }
          case 'Sale':
          case 'SaleReclaim':
          case 'SaleItemReturn':
            return {
              value: item.saleId,
              routerName: 'order-read-only',
              routerParams: { id: item.saleId },
            }
          case 'Inventory':
            return {
              value: item.stockInventoryId,
              routerName: 'stock-inventory-maintain',
              routerParams: { id: item.stockInventoryId },
            }
          default:
            return {}
        }
      }

      return {
        label: this.stockMovementOriginLabel[item.movementOrigin],
        value: config().value,
        routerName: config().routerName,
        routerParams: config().routerParams,
      }
    },
  },
}
</script>

<style lang="scss">
.custom-tooltip-inner .tooltip-inner {
  max-width: 100%;
}

.stock-movement-popup-table {
  .table-container {
    max-height: 60vh;
    overflow-y: scroll;
  }

  .table-tooltip td {
    background-color: white;
  }
}
</style>
