var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-tooltip", {
    ref: "stock_movement_tooltip_" + _vm.id,
    staticClass: "transparent-tooltip",
    attrs: {
      id: "stock_movement_tooltip_" + _vm.id,
      target: _vm.target,
      boundary: "window",
      triggers: "click",
      placement: "left",
      "custom-class": "custom-tooltip-inner",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "stock-movement-popup-table" }, [
              _c(
                "div",
                {
                  staticClass:
                    "p-1 d-flex justify-content-between align-items-center",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "m-0 font-weight-bold",
                      attrs: { title: _vm.getPeriod },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "Movimentações realizadas depois da contagem"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("feather-icon", {
                    staticClass: "cursor-pointer",
                    attrs: { icon: "XIcon", size: "16" },
                    on: { click: _vm.onCloseTooltip },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "table-container" },
                [
                  _c("b-table", {
                    staticClass: "bordered table-tooltip",
                    staticStyle: { "min-width": "400px", "margin-top": "10px" },
                    attrs: {
                      id: "table_" + _vm.id,
                      responsive: "",
                      "show-empty": "",
                      busy: _vm.fetching,
                      "empty-text": _vm.getEmptyTableMessage(
                        _vm.$tc("STOCK_MOVEMENT_REASON.NAME"),
                        "female"
                      ),
                      "primary-key": "id",
                      fields: _vm.fields,
                      items: _vm.getItems,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "table-busy",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "text-center" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("Buscando..."))),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "cell(movementOrigin)",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(item.movementOriginInfo.label)
                              ),
                            ]),
                            item.movementOriginInfo.routerName
                              ? _c("e-router-link", {
                                  attrs: {
                                    to: {
                                      name: item.movementOriginInfo.routerName,
                                      params:
                                        item.movementOriginInfo.routerParams,
                                    },
                                    value: item.movementOriginInfo.value,
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }