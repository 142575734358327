<template>
  <b-modal
    id="modal-inventory-cancelation"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    size="md"
    centered
    @hidden="onHideActions"
  >
    <template #modal-title>
      <p class="h4">
        {{ $t('Cancelar Inventário') }}
      </p>
    </template>

    <FormulateForm
      ref="cancelationForm"
      name="cancelationForm"
    >
      <b-container>
        <b-row align-h="center">
          <b-col
            md="12"
            class="d-flex flex-column"
          >
            <FormulateInput
              id="cancelation_modal-reason"
              v-model="form.cancelationReason"
              name="reason"
              class="w-100 required"
              type="textarea"
              validation="required"
              rows="4"
              :label="$t('Motivo do cancelamento do inventário')"
            />
          </b-col>
          <b-col
            v-if="invoiceId"
            md="12"
            class="d-flex flex-column"
          >
            <FormulateInput
              id="cancelation_modal-invoice_justification"
              v-model="form.invoiceJustification"
              name="invoiceJustification"
              class="w-100 required"
              type="textarea"
              validation="required|min:15"
              rows="4"
              :label="$t('Justificativa do cancelamento da Nota fiscal')"
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            class="d-flex justify-content-end"
            style="gap: 10px"
          >
            <e-button
              :text="$t('Voltar')"
              :text-shortcuts="['ESC']"
              variant="danger"
              @click="onHideModal"
            />
            <e-button
              variant="primary"
              type="submit"
              :busy="busy"
              :text="$t('Confirmar')"
              @click="onConfirm"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { formulateHelper } from '@/mixins'

export default {
  components: { BModal, BContainer, BRow, BCol, EButton },

  mixins: [formulateHelper],

  props: {
    invoiceId: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      busy: false,
      modalResolve: null,
      modalReject: null,
      form: {
        id: null,
        cancelationReason: '',
        invoiceJustification: '',
      },
    }
  },

  computed: {},

  methods: {
    async onConfirm() {
      try {
        this.$refs.cancelationForm.showErrors()
        if (this.$refs.cancelationForm.hasErrors) {
          this.showInvalidDataMessage()
          return
        }
        this.busy = true
        const body = this.form

        await this.$http.patch(`/api/stock-inventories/cancel/${body.id}`, {
          reason: this.form.cancelationReason,
          invoiceJustification: this.form.invoiceJustification,
        })

        this.showSuccess({ message: this.$t('Cancelado com sucesso.') })
        this.$emit('after-confirm')

        this.onHideModal()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.busy = false
      }
    },

    resetForm() {
      this.form = {
        id: '',
        cancelationReason: '',
        invoiceJustification: '',
      }
    },

    onHideModal() {
      this.$bvModal.hide('modal-inventory-cancelation')
    },

    onHideActions() {
      if (this.modalReject) this.modalReject({ message: 'Autorização cancelado' })
    },

    show(id) {
      this.$bvModal.show('modal-inventory-cancelation')
      this.resetForm()
      this.form.id = id
      this.onFocusInput()
    },

    onFocusInput() {
      setTimeout(() => {
        this.focusInput('#cancelation_modal-reason')
      }, 400)
    },
  },
}
</script>

<style lang="scss" scoped></style>
